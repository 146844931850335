import React, { useContext, useReducer } from "react";
import ContactSearchReducer from "~/providers/ContactSearchProvider/ContactSearchReducer";
import ContactSearchContext from "./ContactSearchContext";
import InitialState from "./ContactSearchInitialState";

export const useContactSearch = () => {
  return useContext(ContactSearchContext);
};

export function ContactSearchProvider({
  children,
  hasTooManyContacts,
}: {
  children: React.ReactNode;
  hasTooManyContacts: boolean;
}) {
  const [state, dispatch] = useReducer(ContactSearchReducer, {
    ...InitialState,
    hasTooManyContacts,
  });

  return (
    <ContactSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </ContactSearchContext.Provider>
  );
}

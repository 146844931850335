import { createContext } from "react";
import InitialState from "./ContactSearchInitialState";

import type {
  ActionOptionsWithPayload,
  ContactSearchContextComplete,
} from "./ContactSearchContextType";
const ComposerContext = createContext<ContactSearchContextComplete>({
  state: InitialState,
  dispatch: () => ({}) as ActionOptionsWithPayload,
});

export default ComposerContext;
